import { axiosInstance } from './index';

const handlerEnabled = true;

const getUser = () => {
  return axiosInstance.get('/me', {}, { handlerEnabled });
};

const updateUser = (data) => {
  return axiosInstance.post(`/account/update?method=_PUT`, data, {
    handlerEnabled,
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
};

const updateUserPassword = (data) => {
  return axiosInstance.put(`/account/update-password`, data, {
    handlerEnabled,
  });
};

const createSocial = (data) => {
  return axiosInstance.post(`/account/create-social`, data, { handlerEnabled });
};

const removeSocial = (data) => {
  return axiosInstance.post('/account/remove-social', data, {
    handlerEnabled,
  });
};

const updateCreditCard = (data) => {
  return axiosInstance.post(`/account/update-cc`, data, {
    handlerEnabled,
  });
};

const removeStripeAccount = () => {
  return axiosInstance.delete('/account/stripe/remove', {
    handlerEnabled,
  });
};

const connectStripeAccount = (data) => {
  return axiosInstance.post('/account/stripe/connect', data, {
    handlerEnabled,
  });
};

const stripePurchase = (data) => {
  return axiosInstance.post(`/stripe/purchase`, data);
};

export default {
  getUser,
  updateUser,
  updateUserPassword,
  createSocial,
  updateCreditCard,
  removeSocial,
  removeStripeAccount,
  connectStripeAccount,
  stripePurchase,
};
