import React, { Fragment, Suspense } from 'react';
import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from 'utils/Auth';

import Routes from 'routes';

import theme from 'theme';
import Pace from 'components/Pace';

const App = () => {
  return (
    <AuthProvider>
      <BrowserRouter basename={process.env.REACT_APP_BASEURL || ''}>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <Pace color={theme.palette.secondary.main} />
          <Suspense fallback={<Fragment />}>
            <Routes />
          </Suspense>
        </MuiThemeProvider>
      </BrowserRouter>
    </AuthProvider>
  );
};

export default App;
