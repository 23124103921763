import { call, put, takeLatest } from 'redux-saga/effects';
import API from 'network/apis/APIExample';
import * as ACTIONS from '../actions/Feature1';
import { showError } from 'utils/shared';
import * as TYPES from '../types/Feature1Types';

// Replace with your sagas
export function* feature1Saga() {
  try {
    const response = yield call(API.apiExampleRequest);
    yield put(ACTIONS.feature1ActionReceive(response.data));
  } catch (err) {
    showError(err.response.data);
  }
}

export function* saga1() {
  yield takeLatest(TYPES.GET_DATA_REQUEST, feature1Saga);
}
