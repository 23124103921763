import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { map } from 'lodash';
import MenuIcon from '@material-ui/icons/Menu';
import styles from './LoggedOutMenu.styles';
import {
  Button,
  IconButton,
  Box,
  Menu,
  MenuItem,
  Hidden,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(styles);

const LoggedOutMenu = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isHomePage = useRouteMatch({ path: '/', exact: true });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuItems = [
    { to: '/discover', name: 'Discover' },
    { to: '/support', name: 'FAQ' },
    { to: '/login', name: 'Login' },
  ];

  return (
    <Box>
      <Hidden xsDown>
        {map(menuItems, ({ to, name }) => (
          <Button to={to} component={Link} color="inherit" key={name}>
            {name}
          </Button>
        ))}
      </Hidden>
      <Hidden smUp>
        <IconButton onClick={handleClick}>
          <MenuIcon className={isHomePage && classes.hamburger} />
        </IconButton>

        <Menu
          getContentAnchorEl={null}
          id="header-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {map(menuItems, ({ to, name }) => (
            <MenuItem component={Link} to={to} key={name} onClick={handleClose}>
              {name}
            </MenuItem>
          ))}
        </Menu>
      </Hidden>
    </Box>
  );
};

export default LoggedOutMenu;
