import tokenHandler from './tokenHandler';
import store from 'store';
import * as types from 'store/types/UserTypes';

export async function logout(dispatch) {
  dispatch({ type: 'LOGOUT' });

  store.dispatch({
    type: types.LOGOUT_USER,
  });

  tokenHandler.remove();
}
