import React from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { DISCOVER_PATH, LOGIN_PATH } from './paths';
import { logout, useAuthDispatch, useAuthState } from 'utils/Auth';
import { useSelector } from 'react-redux';
import { getLoggedInUser } from 'utils/shared';
import { includes } from 'lodash';
import NotFound from 'pages/NotFound';
import LoadIndicator from 'components/LoadIndicator';
import queryString from 'query-string';

const AppRoute = ({
  component: Component,
  path,
  isPrivate,
  isGuestOnly,
  roleProtected,
  exact,
  ...rest
}) => {
  const auth = useAuthState();
  const isLoggedIn = !!auth.token;
  const currentUser = useSelector(({ user }) => user);
  const { search } = useLocation();
  const dispatch = useAuthDispatch();

  React.useEffect(() => {
    const checkLoggedIn = async () => {
      return getLoggedInUser();
    };
    if (isLoggedIn && !currentUser.data && !currentUser.isLoading) {
      checkLoggedIn().catch((e) => {
        logout(dispatch);
      });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Route
      path={path}
      exact={exact}
      render={(props) => {
        if (isGuestOnly && isLoggedIn) {
          const queryParams = queryString.parse(search);

          return (
            <Redirect
              to={queryParams.redirect ? queryParams.redirect : DISCOVER_PATH}
            />
          );
        }

        if (isPrivate && !isLoggedIn) {
          if (path === '/logout') {
            return <Redirect to={LOGIN_PATH} />;
          }

          return (
            <Redirect
              to={{
                pathname: LOGIN_PATH,
                search: `?redirect=${rest.location.pathname}`,
              }}
            />
          );
        }

        if (isPrivate && isLoggedIn) {
          if (!currentUser.data || currentUser.isLoading) {
            return <LoadIndicator />;
          }

          if (roleProtected) {
            const { is_creator: isCreator } = currentUser.data;

            const allowed =
              (includes('creator', roleProtected) && isCreator) ||
              (includes('consumer', roleProtected) && !isCreator);

            if (!allowed) {
              return <NotFound />;
            }
          }
        }

        return <Component {...props} />;
      }}
      {...rest}
    />
  );
};

AppRoute.defaultProps = {
  isPrivate: false,
  isGuestOnly: false,
  roleProtected: null,
  exact: false,
};

AppRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  path: PropTypes.string.isRequired,
  exact: PropTypes.bool,
  isGuestOnly: PropTypes.bool,
  isPrivate: PropTypes.bool,
  roleProtected: PropTypes.array,
};

export default AppRoute;
