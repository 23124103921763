import * as types from '../types/UserTypes';

const INITIAL_STATE = {
  isLoading: false,
  error: false,
  data: null,
};

export default (state = INITIAL_STATE, action = null) => {
  switch (action.type) {
    case types.REQUEST_USER:
      return {
        ...state,
        isLoading: true,
        error: false,
        data: null,
      };
    case types.GET_REQUEST_USER:
      return {
        ...state,
        data: action.user,
        isLoading: false,
      };
    case types.LOGOUT_USER:
      return {
        ...state,
        data: null,
        isLoading: false,
        error: false,
      };
    case types.REQUEST_USER_FAILED:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    default:
      return state;
  }
};
