import {
  ACCOUNT_PATH,
  CHECKOUT_PATH,
  CREATOR_ONBOARDING_PATH,
  CREATOR_SETTINGS_PATH,
  DISCOVER_PATH,
  EMAIL_VERIFY_PATH,
  HOME_PATH,
  LOGIN_PATH,
  PROFILE_PATH,
  REGISTER_PATH,
  SUBSCRIBERS_PATH,
  ABOUT_PATH,
  PRIVACY_PATH,
  TERMS_PATH,
  SUPPORT_PATH,
  FORGOT_PASSWORD_PATH,
  RESET_PASSWORD_PATH,
  UNSUBSCRIBE_PATH,
} from './paths';
import HomePage from 'pages/Home';
import LoginPage from 'pages/Login';
import ForgotPassword from 'pages/ForgotPassword';
import ResetPassword from 'pages/ResetPassword';
import AccountPage from 'pages/Account';
import DiscoverPage from 'pages/Discover';
import RegisterPage from 'pages/Register';
import VerifyEmailPage from 'pages/VerifyEmail';
import ProfilePage from 'pages/Profile';

import PrivacyPage from 'pages/Privacy';
import SupportPage from 'pages/Support';
import TermsPage from 'pages/Terms';
import AboutPage from 'pages/About';

import CheckoutPage from 'pages/Checkout';
import CreatorOnboardingPage from 'pages/CreatorOnboarding';
import CreatorSettings from 'pages/CreatorSettings';
import ProfileSubscribers from 'pages/ProfileSubscribers';
import UnsubscribePage from 'pages/Unsubscribe';

export default [
  {
    component: PrivacyPage,
    path: PRIVACY_PATH,
    exact: true,
  },
  {
    component: SupportPage,
    path: SUPPORT_PATH,
    exact: true,
  },
  {
    component: TermsPage,
    path: TERMS_PATH,
    exact: true,
  },
  {
    component: AboutPage,
    path: ABOUT_PATH,
    exact: true,
  },
  {
    component: UnsubscribePage,
    path: UNSUBSCRIBE_PATH,
    exact: true,
  },
  {
    component: HomePage,
    path: HOME_PATH,
    isGuestOnly: true,
    exact: true,
  },
  {
    component: LoginPage,
    path: LOGIN_PATH,
    isGuestOnly: true,
    exact: true,
  },
  {
    component: ForgotPassword,
    path: FORGOT_PASSWORD_PATH,
    isGuestOnly: true,
    exact: true,
  },
  {
    component: ResetPassword,
    path: RESET_PASSWORD_PATH,
    isGuestOnly: true,
    exact: true,
  },
  {
    component: RegisterPage,
    path: REGISTER_PATH,
    isGuestOnly: true,
    exact: true,
  },
  {
    component: VerifyEmailPage,
    path: EMAIL_VERIFY_PATH,
    isGuestOnly: true,
    exact: true,
  },
  {
    component: DiscoverPage,
    path: DISCOVER_PATH,
    exact: true,
  },
  {
    component: AccountPage,
    path: ACCOUNT_PATH,
    isPrivate: true,
  },
  {
    component: CreatorOnboardingPage,
    path: CREATOR_ONBOARDING_PATH,
    isPrivate: true,
    roleProtected: ['consumer'],
  },
  {
    component: CheckoutPage,
    path: CHECKOUT_PATH,
    isPrivate: true,
    exact: false,
  },
  {
    component: CreatorSettings,
    path: CREATOR_SETTINGS_PATH,
    roleProtected: ['creator'],
    isPrivate: true,
  },
  {
    component: ProfileSubscribers,
    path: SUBSCRIBERS_PATH,
    roleProtected: ['creator'],
    isPrivate: true,
  },
  {
    component: ProfilePage,
    path: PROFILE_PATH,
  },
];
