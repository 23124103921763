import tokenHandler from './tokenHandler';

const authToken = tokenHandler.get() || '';

export const initialState = {
  token: '' || authToken,
  loading: false,
  errors: null,
  errorMessage: null,
};

export const AuthReducer = (initialState, action) => {
  switch (action.type) {
    case 'REQUEST_LOGIN':
      return {
        ...initialState,
        errors: null,
        errorMessage: null,
        loading: true,
      };
    case 'LOGIN_SUCCESS':
      return {
        ...initialState,
        token: action.payload,
        errors: null,
        errorMessage: null,
        loading: false,
      };
    case 'LOGOUT':
      return {
        ...initialState,
        errors: null,
        errorMessage: null,
        token: '',
      };
    case 'VALIDATION_ERROR':
      return {
        ...initialState,
        loading: false,
        errors: action.errors,
        errorMessage: action.errorMessage,
      };
    case 'EMAIL_NOT_VERIFIED':
      return {
        ...initialState,
        loading: false,
        errors: null,
        errorMessage: action.errorMessage,
      };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
