export const HOME_PATH = '/';
export const ABOUT_PATH = '/about';
export const SUPPORT_PATH = '/support';
export const TERMS_PATH = '/terms';
export const PRIVACY_PATH = '/privacy';
export const DISCOVER_PATH = '/discover';
export const LOGIN_PATH = '/login';
export const REGISTER_PATH = '/register';
export const FORGOT_PASSWORD_PATH = '/forgot-password';
export const RESET_PASSWORD_PATH = '/reset-password';
export const EMAIL_VERIFY_PATH = '/verify-email/:token';
export const ACCOUNT_PATH = '/account/:subRoute?';
export const CREATOR_ONBOARDING_PATH = '/onboarding/:subRoute?';
export const CREATOR_SETTINGS_PATH = '/distribute/:subRoute?';
export const PROFILE_PATH = '/:slug';
export const SUBSCRIBERS_PATH = '/subscribers';
export const CHECKOUT_PATH = '/:slug/checkout';
export const UNSUBSCRIBE_PATH = '/unsubscribe';
