import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Header from 'components/Header';
import Footer from 'components/Footer';
import ResponsiveHelper from 'utils/ResponsiveHelper';
import styles from './CoreLayout.styles';
import SystemAlerts from 'components/SystemAlerts';
import { Helmet } from 'react-helmet';

const useStyles = makeStyles(styles);

const seoAttributes = {
  title: 'Premium Content on Social Media | Premo Social',
  description:
    'Discover top premium content on Twitter, Instagram, and other social networks through Premo Social!',
  ogImage: `${process.env.REACT_APP_FED_URL}/logo200.png`,
  ogUrl: `${process.env.REACT_APP_FED_URL}`,
  canonical: `${process.env.REACT_APP_FED_URL}`,
  keywords: ['Premo', 'Premo social', 'Premosocial', 'Twitter', 'Instagram'],
};

const CoreLayout = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Helmet defer={false}>
        <title>{seoAttributes.title}</title>
        <meta property="og:title" content={seoAttributes.title} />
        <meta name="description" content={seoAttributes.description} />
        <meta property="og:description" content={seoAttributes.description} />
        <meta property="og:image" content={seoAttributes.ogImage} />
        <link rel="canonical" href={seoAttributes.canonical} />
        <meta property="og:url" content={seoAttributes.ogUrl} />
        <meta name="keywords" content={seoAttributes.keywords.join(',')} />
      </Helmet>
      <Header />
      {children}
      <Footer />
      <ResponsiveHelper env={process.env.REACT_APP_ENV} />
      <SystemAlerts />
    </div>
  );
};

CoreLayout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default CoreLayout;
