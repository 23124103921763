export default (theme) => ({
  root: {},
  headerOffset: theme.mixins.toolbar,
  navbar: {
    backgroundColor: theme.palette.common.white,
    borderBottom: '1px solid #EAEBEE',
  },
  darkNavbar: {
    backgroundColor: theme.palette.common.hero,
  },
  logo: {
    flexGrow: 1,
    '& img': {
      float: 'left',
    },
  },
});
