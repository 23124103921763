import { createMuiTheme } from '@material-ui/core';
import typography from './typography';
import MuiButtonStyles from './overrides/MuiButton.styles';
import MuiInputStyles from './overrides/MuiInput.styles';
import MuiInputBaseStyles from './overrides/MuiInputBase.styles';
import MuiInputLabelStyles from './overrides/MuiInputLabel.styles';
import MuiFormHelperTextStyles from './overrides/MuiFormHelperText.styles';

// colors
const primary = '#3A6AC5';
const primaryDark = '#021E3A';
const secondary = '#46B57F';
const background = '#FAFAFA';
const warningMain = '#FFB100';
const errorMain = '#FA2952';
const successMain = '#46B57F';

// Commons
const black = '#021E3A';
const white = '#FFFFFF';
const darkGray = '#42546F';
const gray = '#94A2B4';
const lightGray = '#D3D8DF';
const backgroundGrey = '#F6F5F7';

// border
const borderWidth = 2;
const borderColor = '#EAEBEE';

// breakpoints
const xl = 1920;
const lg = 1280;
const md = 960;
const sm = 600;
const xs = 0;

// spacing
const spacing = 8;

const theme = createMuiTheme({
  typography,
  palette: {
    type: 'light',
    primary: {
      main: primary,
      dark: primaryDark,
      contrastText: white,
    },
    secondary: {
      main: secondary,
      contrastText: white,
    },
    common: {
      black,
      white,
      gray,
      darkGray,
      lightGray,
      backgroundGrey,
      hero: '#131128',
    },
    success: {
      main: successMain,
    },
    error: {
      main: errorMain,
    },
    warning: {
      main: warningMain,
    },
    // Used to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
    background: {
      default: background,
      paper: white,
    },
    spacing,
  },
  breakpoints: {
    // Define custom breakpoint values.
    // These will apply to Material-UI components that use responsive
    // breakpoints, such as `Grid` and `Hidden`. You can also use the
    // theme breakpoint functions `up`, `down`, and `between` to create
    // media queries for these breakpoints
    values: {
      xl,
      lg,
      md,
      sm,
      xs,
    },
  },
  border: {
    borderColor,
    borderWidth,
  },
  overrides: {
    MuiButton: MuiButtonStyles,
    MuiInput: MuiInputStyles,
    MuiInputBase: MuiInputBaseStyles,
    MuiInputLabel: MuiInputLabelStyles,
    MuiFormHelperText: MuiFormHelperTextStyles,
    MuiExpansionPanel: {
      root: {},
    },
    MuiCheckbox: {},
    MuiTableCell: {
      root: {
        fontSize: 16,
        '&:first-child': {
          paddingLeft: spacing * 4,
        },
        '&:last-child': {
          paddingRight: spacing * 4,
        },
      },
    },
    MuiDivider: {
      root: {},
    },
    MuiListItem: {
      divider: {},
    },
    MuiDialog: {
      paper: {},
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: primaryDark,
      },
    },
    MuiExpansionPanelDetails: {
      root: {},
    },
    MuiSwitch: {
      switchBase: {
        color: secondary,
        '&$checked': {
          color: secondary,
        },
      },
      track: {
        backgroundColor: '#FFFFFF',
        border: '2px solid #999',
        height: 21,
        marginTop: -3,
        borderRadius: 25,
      },
    },
  },
  props: {
    MuiLink: {
      color: 'secondary',
    },
    MuiInputBase: {
      color: 'secondary',
    },
    MuiInputLabel: {
      shrink: true,
    },
    MuiCircularProgress: {
      color: 'secondary',
    },
  },
});

export default theme;
