import { combineReducers } from 'redux';
import snackbar from './Snackbar';
import ReducerExample from './ReducerExample';
import user from './User';

export default combineReducers({
  snackbar,
  user,
  ReducerExample,
});
