import React from 'react';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  responsiveHelper: {
    position: 'fixed',
    bottom: 0,
    right: 0,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: 'white',
    backgroundColor: 'red',
    fontSize: 20,
    fontWeight: 600,
  },
}));

function ResponsiveHelper({ env }) {
  const classes = useStyles();

  if (env !== 'local') {
    return <></>;
  }

  const breakpoints = [
    { name: 'XS', display: { xs: 'block', sm: 'none' } },
    { name: 'SM', display: { xs: 'none', sm: 'block', md: 'none' } },
    { name: 'MD', display: { xs: 'none', md: 'block', lg: 'none' } },
    { name: 'LG', display: { xs: 'none', lg: 'block', xl: 'none' } },
    { name: 'XL', display: { xs: 'none', xl: 'block' } },
  ];

  return (
    <div className={classes.responsiveHelper}>
      {breakpoints.map((b) => (
        <Box display={b.display} key={b.name}>
          <Paper className={classes.paper}>{b.name}</Paper>
        </Box>
      ))}
    </div>
  );
}

ResponsiveHelper.propTypes = {
  env: PropTypes.string,
};

export default ResponsiveHelper;
