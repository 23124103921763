import React from 'react';
import styles from 'components/LoadIndicator/LoadIndicator.styles';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';

const useStyles = makeStyles(styles);

const LoadIndicator = ({ size }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress size={size} />
    </div>
  );
};
LoadIndicator.defaultProps = {
  size: 40,
};

LoadIndicator.propTypes = {
  size: PropTypes.number,
};

export default LoadIndicator;
