import { applyMiddleware, compose, createStore } from 'redux';
import reducers from './reducers';
import createSagaMiddleware from 'redux-saga';
import { watchSagas } from './sagas';

const saga = createSagaMiddleware();
const useDevTool = process.env.REACT_APP_ENV === 'local';

// redux dev tool
// should be available only on local environment
const composeEnhancers =
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
  useDevTool
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const enhancer = composeEnhancers(applyMiddleware(saga));

const store = createStore(reducers, enhancer);

saga.run(watchSagas);

export default store;
