import React from 'react';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Avatar from '@material-ui/core/Avatar';
import { remove } from 'lodash';
import { useSelector } from 'react-redux';
import AuthApi from 'network/apis/Auth';
import { logout, useAuthDispatch } from 'utils/Auth';

const getMenuLinks = (isCreator) => {
  const links = [
    { name: 'Account settings', path: '/account' },
    { name: 'Become a creator', path: '/onboarding', onlyConsumer: true },
    { name: 'Creator settings', path: '/distribute', onlyCreators: true },
    { name: 'Subscribers', path: '/subscribers', onlyCreators: true },
  ];

  return isCreator
    ? remove(links, (l) => !l.onlyConsumer)
    : remove(links, (l) => !l.onlyCreators);
};

const LoggedInMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useAuthDispatch();
  const currentUser = useSelector(({ user }) => user);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logoutUser = (e) => {
    e.preventDefault();
    AuthApi.userLogout().finally(() => {
      logout(dispatch);
    });
  };

  if (!currentUser.data) {
    return <></>;
  }

  return (
    <Box>
      <IconButton
        aria-controls="account-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <Avatar
          alt={currentUser.data.name}
          src={currentUser.data.profile_image}
        />
      </IconButton>
      <Menu
        getContentAnchorEl={null}
        id="account-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {getMenuLinks(currentUser.data.is_creator).map((link, k) => (
          <MenuItem
            to={link.path}
            component={Link}
            onClick={handleClose}
            key={`link-${k}`}
          >
            {link.name}
          </MenuItem>
        ))}

        <MenuItem component={Link} onClick={logoutUser} to="/logout">
          Logout
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default LoggedInMenu;
