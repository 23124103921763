import store from 'store';
import { showSnackbarAction } from 'store/actions/snackbar';
import UserApi from 'network/apis/User';
import * as types from '../store/types/UserTypes';

// To show error message that returned from backend
export function showError(data) {
  let errorMsg = data;

  if (data.error) {
    errorMsg = data.error.message;
  }

  store.dispatch(showSnackbarAction(errorMsg, 'error'));
}

// To show success message after any success request if needed
export function showSuccess(message) {
  store.dispatch(showSnackbarAction(message, 'success'));
}

// To show success message after any success request if needed
export function showWarning(message) {
  store.dispatch(showSnackbarAction(message, 'warning'));
}

export async function getLoggedInUser() {
  store.dispatch({
    type: types.REQUEST_USER,
  });

  return UserApi.getUser()
    .then((res) => {
      store.dispatch({
        type: types.GET_REQUEST_USER,
        user: res.data,
      });

      return true;
    })
    .catch((err) => {
      store.dispatch({
        type: types.REQUEST_USER_FAILED,
        error: err,
      });

      throw new Error(err);
    });
}

export function resizeImage(canvas, w = 250, h = 250, scale = 1) {
  const offScreenCanvas = document.createElement('canvas');
  const width = w * scale;
  const height = h * scale;
  offScreenCanvas.width = width;
  offScreenCanvas.height = height;
  offScreenCanvas.getContext('2d').drawImage(canvas, 0, 0, width, height);
  return offScreenCanvas.toDataURL('image/png', 0.9);
}
