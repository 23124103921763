import { axiosInstance } from './index';

const handlerEnabled = true;

// userPayload = { email, password }
const userLogin = ({ email, password }) => {
  return axiosInstance.post(
    '/login',
    { email, password },
    { handlerEnabled: false },
  );
};

const userLogout = () => {
  return axiosInstance.post('/logout', {}, { handlerEnabled });
};

const registerUser = (data) => {
  return axiosInstance.post(`/register`, data, { handlerEnabled: false });
};

const verifyEmail = (data) => {
  return axiosInstance.post(`/verify-email`, data, { handlerEnabled: false });
};

const sendVerificationEmail = (data) => {
  return axiosInstance.post(`/send-verification-email`, data, {
    handlerEnabled: false,
  });
};

const forgotPassword = (data) => {
  return axiosInstance.post(`/forgot-password`, data, {
    handlerEnabled: false,
  });
};

const resetPassword = (data) => {
  return axiosInstance.post(`/reset-password`, data, {
    handlerEnabled: false,
  });
};

const unsubscribeEmail = (data) => {
  return axiosInstance.post(`/unsubscribe`, data, {
    handlerEnabled: false,
  });
};

export default {
  userLogin,
  userLogout,
  registerUser,
  verifyEmail,
  sendVerificationEmail,
  forgotPassword,
  resetPassword,
  unsubscribeEmail,
};
