export default {
  root: {
    color: '#021E3A',
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: 1,
    textTransform: 'uppercase',
    display: 'flex',
    justifyContent: 'space-between',
    '&$focused, &$error': {
      color: '#021E3A',
    },
    '& a': {
      textTransform: 'none',
      fontSize: 14,
      fontWeight: 400,
    },
  },
  shrink: {
    transform: 'none',
  },
  formControl: {
    position: 'relative',
  },
};
