import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { hideSnackbarAction } from 'store/actions/snackbar';
import styles from './SystemAlerts.styles';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(styles);

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const SystemAlerts = () => {
  const classes = useStyles();
  const { isOpen, message, type } = useSelector((state) => state.snackbar);
  const dispatch = useDispatch();
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(hideSnackbarAction());
  };

  return (
    <Snackbar
      className={classes.root}
      open={isOpen}
      autoHideDuration={6000}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      key={`bottom,center`}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={type}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SystemAlerts;
