import tokenHandler from 'utils/Auth/tokenHandler';

export const isHandlerEnabled = (config = {}) => {
  return !(
    Object.prototype.hasOwnProperty.call(config, 'handlerEnabled') &&
    !config.handlerEnabled
  );
};

export const requestHandler = (requestConfig) => {
  if (isHandlerEnabled(requestConfig)) {
    const token = tokenHandler.get();

    if (token) {
      requestConfig.headers.common.Authorization = `Bearer ${token}`;
    }
  }

  return requestConfig;
};

export const successHandler = (response) => {
  if (isHandlerEnabled(response)) {
    // Handle responses
  }

  // remove nested data object only if there is no pagination available
  if (response.data.data && response.data.per_page === undefined) {
    response.data = response.data.data;
  }

  return response;
};

export const errorHandler = async (error) => {
  if (isHandlerEnabled(error.config)) {
    // You can decide what you need to do to handle errors.
    // here's example for unauthorized user to log them out .
    // error.response.status === 401 && Auth.signOut();
    if (error.respose && error.response.status === 401) {
      tokenHandler.remove();
    }
  }

  return Promise.reject(error);
};
