import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AppRoute from './AppRoute';
import routes from './routes';
import NotFound from 'pages/NotFound';
import CoreLayout from 'layouts/CoreLayout';

const Routes = () => {
  return (
    <CoreLayout>
      <Switch>
        {routes.map((route) => (
          <AppRoute key={route.path} {...route} />
        ))}

        <Route component={NotFound} />
      </Switch>
    </CoreLayout>
  );
};

export default Routes;
