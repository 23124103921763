export default {
  root: {
    '&$error': {
      position: 'absolute',
      ':not(.MuiInput-multiline) + &': {
        top: 63,
      },
      '.MuiInput-multiline + &': {
        bottom: -1,
      },
    },
  },
};
