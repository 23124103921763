import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

const breakpoints = createBreakpoints({});

export default {
  fontFamily: "'Catamaran', sans-serif",
  fontSize: 14,
  useNextVariants: true,
  h1: {
    fontFamily: "'Merriweather', serif",
    fontSize: 96,
    fontWeight: 400,
    lineHeight: '120px',
    [breakpoints.down('sm')]: {
      fontSize: 67,
    },
  },
  h2: {
    fontFamily: "'Merriweather', serif",
    fontSize: 64,
    fontWeight: 400,
    lineHeight: '120px',
    [breakpoints.down('sm')]: {
      fontSize: 51,
    },
  },
  h3: {
    fontFamily: "'Merriweather', serif",
    fontSize: 48,
    fontWeight: 700,
    lineHeight: '60px',
    [breakpoints.down('sm')]: {
      fontSize: 39,
    },
  },
  h4: {
    fontFamily: "'Merriweather', serif",
    fontSize: 36,
    fontWeight: 700,
    lineHeight: '48px',
    [breakpoints.down('sm')]: {
      fontSize: 28,
    },
  },
  h5: {
    fontFamily: "'Merriweather', serif",
    fontSize: 24,
    fontWeight: 700,
    lineHeight: '32px',
    [breakpoints.down('sm')]: {
      fontSize: 21,
    },
  },
  h6: {
    fontSize: 18,
    fontWeight: 400,
    lineHeight: '32px',
  },
  subtitle1: {
    fontSize: 18,
    fontWeight: 900,
    lineHeight: '32px',
  },
  subtitle2: {
    fontSize: 18,
    fontWeight: 600,
    lineHeight: '32px',
  },
  body1: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '28px',
  },
  body2: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '24px',
  },
  button: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: 1,
  },
  caption: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: 1,
  },
  overline: {},
};
